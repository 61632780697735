:root {
  /*--color-1: var(--accent);*/
  --color-1: #ccc;
}

body {
  margin: 0 !important;
}

.Button--lvl-primary {
  color: white;
  background: var(--color-1);
  border-color: var(--color-1);
}

.Button--lvl-outline {
  color: var(--color-1);
  box-shadow: inset 0 0 0 1px var(--color-1);
}

.vkuiCheckbox__icon--on {
  color: var(--color-1);
}

.vkuiRadio--ios .vkuiRadio__input:checked~.vkuiRadio__container .vkuiRadio__icon {
  border-color: var(--color-1);
}
.vkuiRadio--ios .vkuiRadio__input:checked~.vkuiRadio__container .vkuiRadio__icon::after {
  background-color: var(--color-1);
}
.vkuiSwitch--ios .vkuiSwitch__self:checked+.vkuiSwitch__pseudo {
  background-color: var(--color-1);
}

/*.vkuiPanelHeader__in {
  background: var(--color-1);
}
.vkuiPanelHeader__content-in {
  color: #fff;
}*/

/*.PersonalHeader {
  background: var(--color-1); 
}
.PersonalHeader .vkuiPanelHeader__in {
  background: var(--color-1); 
}
.PersonalHeader .vkuiPanelHeader__content-in {
  color: #fff;
}*/
.PersonalHeader__Name {
  /*position: relative;*/
  /*background: var(--color-1); */
  /*color: #fff;*/
  font-size: 25px;
  font-weight: 600;
}
/*.PersonalHeader__Name:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 10;
  top: -1000px;
  left: 0;
  width: 100%;
  height: 1000px;
  background: var(--color-1); 
}*/


.vkuiPanelHeader__content-in {
  font-size: 18px !important;
}

.vkuiTabbarItem--selected {
  color: var(--color-1);
}

.vkuiPanelHeader__left {
  color: var(--color-1); 
}


.BackgroundPage {
  position: relative;
  background: var(--background_page);
  box-shadow: 0 100px 0 100px var(--background_page);
}
.BackgroundPage::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  /*left: -20px;
  right: -20px;*/
  width: 100%;
  height: 150px;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}
[scheme="space_gray"] .BackgroundPage::before {
  display: none;
}
.BackgroundPage .Header {
  position: relative;
}


.Warning {
  padding: 10px 12px;
  background-color: #ffebee;
  border-top: 1px solid #ffcdd2;
  border-bottom: 1px solid #ffcdd2;
  margin: 5px 0;
}
.Warning__Text {
  color: var(--dynamic_red);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.Message {
  padding: 10px 12px;
  background-color: #ffffff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  margin: 5px 0;
  color: #333333;
}

.Message--type_light {
  background-color: #fefefe;
  border-color: #fdfdfe;
  color: #818182;
}
.Message--type_dark {
  background-color: #d6d8d9;
  border-color: #c6c8ca;
  color: #1b1e21;
}
.Message--type_primary {
  background-color: #cce5ff;
  border-color: #b8daff;
  color: #004085;
}
.Message--type_secondary {
  background-color: #e2e3e5;
  border-color: #d6d8db;
  color: #383d41;
}
.Message--type_success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}
.Message--type_danger {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}
.Message--type_warning {
  background-color: #fff3cd;
  border-color: #ffeeba;
  color: #856404;
}
.Message--type_info {
  background-color: #d1ecf1;
  border-color: #bee5eb;
  color: #0c5460;
}

.Message__Title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 4px;
}
.Message__Description {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 2px;
}



.AppButton {
  display: block;
  text-decoration: none;
  overflow: hidden;
  text-indent: -1000px;
  width: 148px;
  height: 44px;
  background-size: contain !important;
}
.AppButton:active {
  opacity: 0.6;
}
.AppButton--ios {
  background: url('https://dlvry.ru/img/mobile-app-button-ios--light.svg') no-repeat center center;
}
.AppButton--android {
  background: url('https://dlvry.ru/img/mobile-app-button-android--light.svg') no-repeat center center;
}

.Action__Img {
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.Action__Img img {
  width: 100%;
}
.Action__Promocode {
  box-shadow: none;
  border: 1.5px dashed var(--color-1);
}
.Action__Promocode .Button__content {
  letter-spacing: 1px;
  line-height: 1;
  color: var(--color-1);
}


.ActionDetail__Img {
  overflow: hidden;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}
.ActionDetail__Img img {
  width: 100%;
}
.ActionDetail__Bottom {
  border-top: 0.5px solid var(--field_border);
  background: var(--modal_card_background);
  box-shadow: 0 100px 0 100px var(--modal_card_background);
}


.HomeDelivery {
  padding: 10px; 
  background: var(--color-1);
  /*border: 1px solid var(--input_border);*/
  border-radius: 13px;
}
.HomeDelivery .vkuiSliderSwitch {
  background: var(--background_content);
}
.HomeDelivery .vkuiSliderSwitch--firstActive,
.HomeDelivery .vkuiSliderSwitch--secondActive {
  background: var(--color-1);
}
.HomeDelivery .vkuiSliderSwitch__button--active {
  color: #fff;
}


.HomeConditions {
  padding: 0px 0;
  /*background: red;*/
  margin-top: 10px;
  /*margin-bottom: 10px;*/
}
.HomeConditions__Container {
  display: flex;
  padding: 0px 12px;
}
.HomeConditions__Card {
  margin: 0 6px;
}
.HomeConditions__Card:first-child {
  margin-left: 0;
}
.HomeConditions__Card:last-child {
  margin-right: 0;
}
.HomeConditions__Item { 
  padding: 8px 12px;
  /*border-radius: 10px;*/
  /*background: var(--color-1);*/
  white-space: nowrap;
  text-align: center;
}
.HomeConditions__ItemTitle {
  font-size: 12px;
  font-weight: 400;
}
.HomeConditions__ItemValue {
  font-size: 15px;
  font-weight: 600;
}


.HomeActions {
  margin-top: 10px;
  padding: 0px 0;
  /*background: red;*/
  margin-bottom: 10px;
}
.HomeActions__Container {
  display: flex;
  padding: 0px 12px;
}
.HomeActions__Item {
  overflow: hidden;
  border-radius: 10px;
  margin: 0 6px;
}
.HomeActions__Item:first-child {
  margin-left: 0;
}
.HomeActions__Item:last-child {
  margin-right: 0;
}
.HomeActions__Item img {
  display: block;
  width: 100%;
}


.HorizontalMenu {
  z-index: 2;
  background: var(--background_content); 
  position: sticky; 
  top: var(--safe-area-inset-top);
  padding: 10px 0;
  /*border-top: 1px solid var(--tabbar_background);*/
  /*margin-top: -5px;*/
  /*border-bottom: 1px solid var(--tabbar_background);*/
}
.HorizontalMenu--ios {
  top: calc(var(--safe-area-inset-top) + var(--panelheader_height_ios));
}
.HorizontalMenu--android {
  top: calc(var(--safe-area-inset-top) + var(--panelheader_height_android));
}
.HorizontalMenu__Container {
  display: flex;
  padding: 0 12px;
}
.HorizontalMenu .Button {
  border-radius: 100px;
  margin: 0 5px;
  background: transparent;
  border: 1px solid var(--input_border);
  color: var(--text_secondary);
}
.HorizontalMenu .Button--lvl-primary {
  color: white;
  background: var(--color-1);
  border-color: var(--color-1);
}
.HorizontalMenu .Button:first-child {
  margin-left: 0;
}
.HorizontalMenu .Button:last-child {
  margin-right: 0;
}
.HorizontalMenu .Button__content {
  line-height: 1;
}


.HorizontalSubmenu {
  background: var(--background_content); 
}
.HorizontalSubmenu__Container {
  display: flex;
  padding: 0 12px 10px;
}
.HorizontalSubmenu .Button {
  border-radius: 100px;
  margin: 0 5px;
  background: transparent;
  border: 1px solid var(--input_border);
  color: var(--text_secondary);
}
.HorizontalSubmenu .Button--lvl-primary {
  color: white;
  background: var(--color-1);
  border-color: var(--color-1);
}
.HorizontalSubmenu .Button:first-child {
  margin-left: 0;
}
.HorizontalSubmenu .Button:last-child {
  margin-right: 0;
}
.HorizontalSubmenu .Button__content {
  line-height: 1;
}


.ProductCard {
  margin-bottom: 15px;
  background: var(--modal_card_background);
  border-radius: 10px;
  overflow: hidden;
  word-break: break-word;
}
.ProductCard--medium {
  position: relative;
  display: flex;
  flex-direction: row;
  /*background: red;*/
  padding: 10px 0 0 10px;
  min-height: 100px;
  /*border-radius: 3px;*/
  /*border-bottom: 0.75px solid #eee;*/
}
.ProductCard--medium:last-child {
  border-bottom: none;
}
.ProductCard__Img {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-size: cover;
  background-position: center center;
}
.ProductCard--medium .ProductCard__Img {
  width: 90px;
  height: 90px;
  border-radius: 5px;
}
.ProductCard__Img img {
  display: block;
  width: 100%;
}
.ProductCard__Labels {
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;  
}
.ProductCard--medium .ProductCard__Labels {
  position: relative;
  top: 0;
  left: 0;
}
.ProductCard__Label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 1px;
  border-radius: 3px;
}
.ProductCard__LabelIcon {

}
.ProductCard__LabelText {

}
.ProductCard__Options {
  margin-bottom: 10px;
}
.ProductCard__Text {
  padding: 10px;
}
.ProductCard--medium .ProductCard__Text {
  padding: 0 10px;
}
.ProductCard--noimage .ProductCard__Text {
  padding: 0;
}
.ProductCard__Title {
  font-size: 18px;
  font-weight: 500;
  color: var(--color-1);
  margin-bottom: 4px;
}
.ProductCard--medium .ProductCard__Title {
  margin-bottom: 10px;
}
.ProductCard__Title span {
  font-size: 15px;
  font-weight: 400;
  color: var(--text_secondary);
}
.ProductCard__Description {
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
  color: var(--text_secondary);
}
.ProductCard__Bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  min-height: 36px;
  border-top: 0.75px solid var(--field_border);
}
.ProductCard--medium .ProductCard__Bottom {
  border-top: none;
}
.ProductCard--noimage .ProductCard__Bottom {
  padding-left: 0;
  padding-right: 0;
}
.ProductCard__Price {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 18px;
  line-height: 34px;
  font-weight: 500;
  color: var(--text_primary);
}
.ProductCard--medium .ProductCard__Price {
  flex-direction: column;
}
.ProductCard--noimage .ProductCard__Price {
  flex-direction: row;
}
.ProductCard__PriceOld {
  margin-right: 10px;
  position: relative;
  font-weight: 400;
  color: var(--dynamic_red);
}
.ProductCard--medium .ProductCard__PriceOld {
  margin-right: 0;
  margin-bottom: -5px;
  line-height: 1;
}
.ProductCard--noimage .ProductCard__PriceOld {
  margin-right: 15px;
  margin-bottom: 0;
  line-height: 34px; 
}
.ProductCard__PriceOld::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -15%;
  width: 130%;
  height: 1px;
  background: var(--dynamic_red);
  transform: rotate(-12deg);
}
.ProductCard--medium .ProductCard__PriceOld::before {
  left: -10%;
  width: 115%;
  transform: rotate(-6deg);
}
.ProductCard__PlusMinus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ProductCard__PlusMinus .Button {
  width: 36px;
  padding-left: 3px;
  padding-right: 0;
  /*border-radius: 100px;*/
}
.ProductCard__PlusMinus span {
  display: block;
  font-size: 17px;
  font-weight: 400;
  color: var(--text_primary);
  text-align: center;
  min-width: 28px;
  padding: 0 3px;
}



.RecommendCard {
  margin: 0px 12px 12px 0;
  padding: 7px;
  min-width: 330px;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  background-color: var(--modal_card_background);
  border: 1px solid var(--field_border);
  border-radius: 5px;
}
.RecommendCard__Img {
  width: 120px;
  height: 120px;
  border-radius: 3px;
  background-size: cover;
  background-position: center center;
}
.RecommendCard__Inner {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
}
.RecommendCard__Text {}
.RecommendCard__Title {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-1);
  margin-bottom: 5px;
}
.RecommendCard__Price {
  font-size: 17px;
  font-weight: 600;
  color: var(--text_primary);
}
.RecommendCard__Buy {
  text-align: right;
  /*display: flex;
  flex-direction: row;*/
}




.ProductDetail {
  padding-bottom: 80px;
}
.ProductDetail__Title {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 500;
  color: var(--color-1);
  margin-bottom: 4px;
}
.ProductDetail__Title span {
  font-size: 20px;
  font-weight: 400;
  color: var(--text_secondary);
}
.ProductDetail__Description {
  font-size: 17px;
  line-height: 22px;
  font-weight: 400;
  color: var(--text_secondary);
  margin-bottom: 15px;
}
.ProductDetail__Labels {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.ProductDetail__Label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 1px;
  border-radius: 3px;
}
.ProductDetail__LabelIcon {

}
.ProductDetail__LabelText {

}
.ProductDetail__Nutritional {
  background-color: var(--color-1);
  padding: 15px 16px 10px;
  border-radius: 14px;
}
.ProductDetail__NutritionalHeader {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 8px;
  color: rgba(255,255,255,0.7);
}
.ProductDetail__NutritionalItem {
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ProductDetail__NutritionalText {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
.ProductDetail__Bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 0.5px solid var(--field_border);
  background: var(--background_content);
  box-shadow: 0 100px 0 100px var(--background_content);
}
.ProductDetail__Price {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 20px;
  line-height: 38px;
  font-weight: 500;
  color: var(--text_primary);
}
.ProductDetail__PriceOld {
  margin-right: 15px;
  position: relative;
  font-weight: 400;
  color: var(--dynamic_red);
}
.ProductDetail__PriceOld::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -15%;
  width: 130%;
  height: 1px;
  background: var(--dynamic_red);
  transform: rotate(-12deg);
}
.ProductDetail__PlusMinus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ProductDetail__PlusMinus .Button {
  width: 44px;
  padding-left: 7px;
  padding-right: 0;
  /*border-radius: 100px;*/
}
.ProductDetail__PlusMinus span {
  display: block;
  font-size: 20px;
  font-weight: 400;
  color: var(--text_primary);
  text-align: center;
  min-width: 38px;
  padding: 0 3px;
}
.ProductDetail__MinQnt {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 15px;
  color: var(--text_secondary);
}
.ProductDetail__Properties {
  margin: 0 -12px 15px;
}
.ProductDetail__PropertiesHeader {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--text_secondary);
  margin: 0 12px 5px;
}
.ProductDetail__PropertiesHeader--error {
  color: var(--dynamic_red);
}
.ProductDetail__PropertiesSubheader {
  font-size: 14px;
  color: #AAAAAA;
  margin: -5px 12px 5px;
}



.SetPhone__Page {
  display: flex;
  flex: 1;
  height: 100%;
  padding-bottom: 60px;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
}
.SetPhone {
  text-align: center;
}
.SetPhone__Header {
  margin-bottom: 15px;
}
.SetPhone__Policy {
  font-size: 13px;
  color: var(--text_secondary);
}
.SetPhone__Policy a {
  color: var(--color-1);
}




.CartItem {
  position: relative;
  padding-bottom: 20px;
  margin: 0 12px;
}
.CartItem:last-child {
  padding-bottom: 0;
}
.CartItem::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.75px;
  position: absolute;
  bottom: 10px;
  left: 0;
  background: #eee;
}
.CartItem:last-child::after {
  display: none;
}
.CartItem__Action {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
}
.CartItem__ActionText {
  font-size: 15px;
  font-weight: 400;
  color: var(--dynamic_orange);
}
.CartItem__ActionIcon {
  margin-right: 3px;
  color: var(--dynamic_orange);
}
.CartItem__Name {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: var(--text_primary);
}
.CartItem__Properties {
  margin-top: 2px;
  line-height: 1.2;
}
.CartItem__Property {
  font-size: 14px;
  color: var(--text_secondary);
}
.CartItem__Buy {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  justify-content: space-between;
  line-height: 30px;
}
.CartItem__Sum {
  font-size: 16px;
  color: var(--text_secondary);
}

.CartItem__PlusMinus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.CartItem__PlusMinus .Button {
  width: 36px;
  padding-left: 3px;
  padding-right: 0;
  border-radius: 100px;
}
.CartItem__PlusMinus span {
  display: block;
  font-size: 17px;
  font-weight: 500;
  color: var(--text_primary);
  text-align: center;
  min-width: 28px;
  padding: 0 3px;
}

.PromocodeResult {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px 10px 5px;
  /*box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.1);*/
}
.PromocodeResult__Status {
  display: inline-block;
  padding: 5px 6px 4px;
  margin-bottom: 10px;
  border: 1px solid var(--color-1);
  border-radius: 3px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-1);
}
.PromocodeResult__Status--success {
  border-color: var(--dynamic_green);
  color: var(--dynamic_green);
}
.PromocodeResult__Status--warning {
  border-color: var(--dynamic_orange);
  color: var(--dynamic_orange);
}
.PromocodeResult__Title {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.2px;
  margin-bottom: 5px;
  color: var(--text_primary);
}
.PromocodeResult__Description {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 5px;
  color: var(--text_secondary);
}
.PromocodeResult__Description--warning {
  color: var(--dynamic_orange);
}
.PromocodeResult__Description--error {
  color: var(--dynamic_red);
}


.SectionsGrid {
  position: relative;
}
.SectionsGrid__Item {
  margin-bottom: 15px;
  background: var(--modal_card_background);
  overflow: hidden;
  border-radius: 10px;
  /*border: 2px solid #fff;*/
}
.SectionsGrid__ItemImg {
  overflow: hidden;
  /*border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;*/
}
.SectionsGrid__ItemImg img {
  display: block;
  width: 100%;
}
.SectionsGrid__ItemTitle {
  padding: 7px 10px 10px;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 500;
  color: var(--color-1);
}





